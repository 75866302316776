import LoadingIndicator from "assets/svg/loading-indicator";

export function NumberLoader({ children, loading }) {
  return loading ? (
    <div className="inline-block w-4">
      <LoadingIndicator />
    </div>
  ) : (
    <>{children}</>
  );
}
