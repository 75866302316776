import { NumberLoader } from "components/global/numberLoader";
import numeral from "numeral";

export default function PopoutA({
  data,
  abTest,
  abOngoing,
  index,
  type,
  winner,
}) {
  const typeAClass = abOngoing ? "bg-secondary" : "bg-lightGreen";
  const typeBClass = abOngoing
    ? "bg-primary"
    : "bg-default text-black opacity-40";

  return (
    <div className="flex relative gap-4 items-center" key={index}>
      {type && abTest && (
        <div className={`ab-test ${winner === "A" ? typeAClass : typeBClass}`}>
          {type}
        </div>
      )}
      <div
        className={`flex divide-x divide-gray ${
          winner === "B" && !abOngoing && "opacity-40"
        }`}
      >
        <div className="pr-6">
          <h3 className="text-2xl">
            <NumberLoader loading={!data?.summary}>
              {numeral(data?.summary?.opens || 0).format("0,0")}
            </NumberLoader>
          </h3>
          <small className="font-extralight text-mutedGray">
            Unique Visitors
          </small>
        </div>
        <div className="px-6">
          <h3 className="text-2xl">
            <NumberLoader loading={!data?.summary}>
              {numeral(data?.summary?.clicks || 0).format("0,0")}
            </NumberLoader>
          </h3>
          <small className="font-extralight text-mutedGray">Unique Opens</small>
        </div>
        <div className="pl-6">
          <h3 className="flex items-center text-2xl">
            <NumberLoader loading={!data?.summary}>
              {numeral(data?.summary ? data?.summary?.interactions : 0).format(
                "0,0"
              )}
            </NumberLoader>
          </h3>
          <small className="font-extralight text-mutedGray">
            Unique Interactions
          </small>
        </div>
      </div>
    </div>
  );
}
