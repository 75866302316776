import _ from "lodash";
import { useEffect, useState } from "react";
import http from "services/https";
import PopoutA from "./table-statistics/popout-a";
import PopoutB from "./table-statistics/popout-b";

export default function PopoutData({ data, abTest = false, abOngoing = true }) {
  const [reportA, setReportA] = useState(null);
  const [reportB, setReportB] = useState(null);

  useEffect(() => {
    if (data.popup_a_id) {
      (async () => {
        const popoutId = data.popup_a_id;
        const campaignPopoutId = data.id;
        const req = await http.get(`reports/get`, {
          popup_id: popoutId,
          campaign_popup_id: campaignPopoutId,
          start: data.updated_at,
        });
        setReportA(req.data.report);
      })();
    }
    if (data.popup_b_id) {
      (async () => {
        const popoutId = data.popup_b_id;
        const campaignPopoutId = data.id;
        const req = await http.get(`reports/get`, {
          popup_id: popoutId,
          campaign_popup_id: campaignPopoutId,
          start: data.updated_at,
        });
        setReportB(req.data.report);
      })();
    }
  }, [data]);

  const getWinner = () => {
    let result = "A";
    if (!_.isEmpty(data?.preferred_popup)) {
      if (data.preferred_popup.id === data.popup_a_id) {
        result = "A";
      } else if (data.preferred_popup.id === data.popup_b_id) {
        result = "B";
      }
    }
    return result;
  };
  return (
    <div className="flex flex-col space-y-5">
      {/* POPUP A */}
      <PopoutA
        index={`popup-a-test-${data.popup_a_id}`}
        abOngoing={abOngoing}
        data={{ ...data.popup_a, summary: reportA }}
        abTest={abTest}
        type="A"
        winner={getWinner()}
      />

      {/* POPUP B */}
      {data.popup_b_id !== null && (
        <PopoutB
          index={`popup-a-test-${data.popup_b_id}`}
          abOngoing={abOngoing}
          data={{ ...data.popup_b, summary: reportB }}
          type="B"
          winner={getWinner()}
        />
      )}
    </div>
  );
}
